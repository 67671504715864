const wenContract = "WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk";
const popcatContract = "7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr";
const mogContract = "0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a";
const toshiContract = "0xAC1Bd2486aAf3B5C0fc3Fd868558b082a531B2B4";
const usdcContract = "F23fFqpRNsmWjuUrFpfM1pvoVvMSpLuN6hY978Y1JXLt";
const vonspeedContract = "DVzrCErBzydh92bBzSJX1dKwVvb4omwhrvNz8CwRyxxV";
const bcatContract = "0x0590cc9232eBF68D81F6707A119898219342ecB9";
const chonkyContract = "H7ed7UgcLp3ax4X1CQ5WuWDn6d1pprfMMYiv5ejwLWWU";
const smurfCat = "0xfF836A5821E69066c87E268bC51b849FaB94240C";
const hemuleContract = "0xeAA63125dd63f10874F99CdBbb18410e7Fc79dD3";
const beamCatContract = "0x61b24C2755371E0AAfFd5f7fc75a3bc074f9a56E";
const elgatoContract = "F47vvwFYuLioQsqEVAjqdY6Yihc8wVRiUcfHGcBR9XUs";
const vcatContract = "VP9UaBXLM4KYRvyjisu65rz8BU5xNAbewU7LVmyU2x4";
const lmeowContract = "0x1aE7e1d0ce06364CED9aD58225a1705b3e5DB92b";

export const contracts = [
  vonspeedContract,
  elgatoContract,
  beamCatContract,
  bcatContract,
  usdcContract,
  chonkyContract,
  smurfCat,
  hemuleContract,
  toshiContract,
  popcatContract,
  wenContract,
  mogContract,
];

export const tableContracts = [
  vonspeedContract,
  elgatoContract,
  beamCatContract,
  bcatContract,
  vcatContract,
  usdcContract,
  lmeowContract,
  chonkyContract,
  smurfCat,
  hemuleContract,
  toshiContract,
  popcatContract,
  wenContract,
  mogContract,
];
