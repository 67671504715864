import styles from "../styles/Home.module.css";
import { useEffect, useState } from "react";

export function PageViews() {
  const [count, setCount] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const siteId = "catmememegacycle.xyz";
    const period = "12mo";
    const filters = encodeURIComponent(`event:page==${"/"}`);

    const params = `site_id=${siteId}&period=${period}&filters=${filters}`;
    const url = `https://plausible.io/api/v1/stats/aggregate?${params}`;
    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_PLAUSIBLE_API_KEY}`,
    };
    fetch(url, { headers })
      .then((res) => res.json())
      .then((data) => {
        setCount(data.results.visitors.value);
        setLoading(false);
      });
  }, []);

  if (isLoading)
    return (
      <p className={styles.visitorCount}>Loading unique visitor count...</p>
    );

  return <p className={styles.visitorCount}>Unique visitors: {count}</p>;
}
