import { useEffect, useState } from 'react'

function useMediaQuery(query, values, defaultValue) {
  const getMatches = (query, values, defaultValue) => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      if (Array.isArray(query) && values && defaultValue) {
        return (
          values[query.findIndex((q) => window.matchMedia(q).matches)] ||
          defaultValue
        )
      }
      if (typeof query === 'string') {
        return window.matchMedia(query).matches
      }
    }
    return false
  }

  const [matches, setMatches] = useState(
    getMatches(query, values, defaultValue)
  )

  function handleChange() {
    setMatches(getMatches(query, values, defaultValue))
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query)

    // Triggered at the first client-side load and if query changes
    handleChange()

    // Listen matchMedia
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange)
    } else {
      matchMedia.addEventListener('change', handleChange)
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange)
      } else {
        matchMedia.removeEventListener('change', handleChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return matches
}

export default useMediaQuery
