import "./App.css";
import styles from "./styles/Home.module.css";
import Chart from "./components/Chart";
import { PageViews } from "./components/PageViews";
import { Suspense, useState } from "react";
import useMediaQuery from "./utils/useMediaQuery";
import Table from "./components/Table";

function App() {
  const [isTableView, setIsTableView] = useState(false);
  const matches768 = useMediaQuery("(max-width: 768px)");

  return (
    <div>
      <div>
        <title>Cat meme megacycle</title>
        <link rel="icon" href="/favicon.ico" />
      </div>

      <main>
        <h1 className={styles.title}>
          Cat meme <a href="https://www.andreavonspeed.com/">MEGACYCLE</a>
        </h1>

        <Suspense
          fallback={
            <p className="tracking-tight text-zinc-100">
              Disable ad/tracking blockers to see visitor count!
            </p>
          }
        >
          <PageViews />
        </Suspense>

        <div className={styles.chart}>
          {!isTableView && (
            <p
              onClick={() => setIsTableView(!isTableView)}
              className="cursor-pointer font-semibold tracking-tight text-zinc-100 xl:font-bold text-xl absolute left-48 top-12 -rotate-12 text-center font-indieFlower text-3xl sm:left-64 sm:top-16 lg:text-4xl transition ease-in hover:scale-105 duration-100"
            >
              Switch to{" "}
              <span className="absolute left-[1.5rem] -bottom-14 lg:-bottom-20 rotate-8">
                table view
              </span>
            </p>
          )}
          {!isTableView ? (
            <Chart matches768={matches768} />
          ) : (
            <Table setIsTableView={setIsTableView} />
          )}
        </div>
      </main>

      {!isTableView && (
        <footer>
          <div className={styles.websiteLogoWrapper}>
            Made with love by
            <a
              href="https://tamasbogdan.netlify.app"
              target="_blank"
              rel="noreferrer"
              className={styles.websiteLogo}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#FFFFFF"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM8.547 4.505a8.25 8.25 0 1 0 11.672 8.214l-.46-.46a2.252 2.252 0 0 1-.422-.586l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.211.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.654-.261a2.25 2.25 0 0 1-1.384-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.279-2.132Z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>
        </footer>
      )}

      <style jsx>{`
        main {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 35px;
        }
        footer {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          color: #ffffff;
          padding: 25px;
        }
        footer a {
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
        }
      `}</style>

      <style jsx global>{`
        html,
        body {
          height: 100%;
          background: #181a1b;
          padding: 0;
          margin: 0;
          font-family:
            -apple-system,
            BlinkMacSystemFont,
            Segoe UI,
            Roboto,
            Oxygen,
            Ubuntu,
            Cantarell,
            Fira Sans,
            Droid Sans,
            Helvetica Neue,
            sans-serif;
        }
        * {
          box-sizing: border-box;
        }
      `}</style>
    </div>
  );
}

export default App;
