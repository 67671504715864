import React, { Component } from "react";
import { Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import wen from "../images/wen.jpg";
import popcat from "../images/popcat.png";
import mog from "../images/mog.png";
import toshi from "../images/toshi.jpg";
import usdc from "../images/usdc.png";
import vonspeed from "../images/vonspeed.webp";
import beamcat from "../images/beamcat.webp";
import bananacat from "../images/bcat.png";
import chonky from "../images/chonky.webp";
import hemule from "../images/hemule.png";
import smurfcat from "../images/smurfcat.jpg";
import elgatocat from "../images/elgato.jpg";

import axios from "axios";
import { contracts } from "../utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

class Chart extends Component {
  constructor(props) {
    super(props);

    const popcatImg = !this.props.matches768
      ? new Image(60, 60)
      : new Image(30, 30);
    popcatImg.src = popcat;

    const wenImg = !this.props.matches768
      ? new Image(60, 60)
      : new Image(30, 30);
    wenImg.src = wen;

    const mogImg = !this.props.matches768
      ? new Image(60, 60)
      : new Image(30, 30);
    mogImg.src = mog;

    const elgatoImg = !this.props.matches768
      ? new Image(60, 60)
      : new Image(30, 30);
    elgatoImg.src = elgatocat;

    const toshiImg = !this.props.matches768
      ? new Image(60, 60)
      : new Image(30, 30);
    toshiImg.src = toshi;

    const usdcImg = !this.props.matches768
      ? new Image(60, 60)
      : new Image(30, 30);
    usdcImg.src = usdc;

    const bcatImg = !this.props.matches768
      ? new Image(90, 90)
      : new Image(40, 40);
    bcatImg.src = bananacat;

    const hemuleImg = !this.props.matches768
      ? new Image(60, 60)
      : new Image(30, 30);
    hemuleImg.src = hemule;

    const beamCatImg = !this.props.matches768
      ? new Image(60, 60)
      : new Image(30, 30);
    beamCatImg.src = beamcat;

    const smurfCatImg = !this.props.matches768
      ? new Image(60, 60)
      : new Image(30, 30);
    smurfCatImg.src = smurfcat;

    const chonkyImg = !this.props.matches768
      ? new Image(60, 60)
      : new Image(30, 30);
    chonkyImg.src = chonky;

    const vonspeedImg = !this.props.matches768
      ? new Image(130, 130)
      : new Image(70, 70);
    vonspeedImg.src = vonspeed;

    this.data = [
      { name: "$VONSPEED", market_cap: 0, icon: vonspeedImg },
      { name: "$ELGATO", market_cap: 0, icon: elgatoImg },
      { name: "$BCAT", market_cap: 0, icon: beamCatImg },
      { name: "$BCAT", market_cap: 0, icon: bcatImg },
      { name: "$USDC", market_cap: 0, icon: usdcImg },
      { name: "$CHONKY", market_cap: 0, icon: chonkyImg },
      { name: "$SMURFCAT", market_cap: 0, icon: smurfCatImg },
      { name: "$HEMULE", market_cap: 0, icon: hemuleImg },
      { name: "$TOSHI", market_cap: 0, icon: toshiImg },
      { name: "$POPCAT", market_cap: 0, icon: popcatImg },
      { name: "$WEN", market_cap: 0, icon: wenImg },
      { name: "$MOG", market_cap: 0, icon: mogImg },
    ];

    this.state = {
      data: [
        { name: "$VONSPEED", market_cap: 0, icon: vonspeedImg },
        { name: "$ELGATO", market_cap: 0, icon: elgatoImg },
        { name: "$BCAT", market_cap: 0, icon: beamCatImg },
        { name: "$BCAT", market_cap: 0, icon: bcatImg },
        { name: "$USDC", market_cap: 0, icon: usdcImg },
        { name: "$CHONKY", market_cap: 0, icon: chonkyImg },
        { name: "$SMURFCAT", market_cap: 0, icon: smurfCatImg },
        { name: "$HEMULE", market_cap: 0, icon: hemuleImg },
        { name: "$TOSHI", market_cap: 0, icon: toshiImg },
        { name: "$POPCAT", market_cap: 0, icon: popcatImg },
        { name: "$WEN", market_cap: 0, icon: wenImg },
        { name: "$MOG", market_cap: 0, icon: mogImg },
      ],
    };

    this.scatterDataLabels = {
      id: "scatterDataLabels",
      props: this.props,
      afterDatasetsDraw(chart, args, options, cancelable) {
        let xOffset = 10;
        let yOffset = 65;
        const { ctx } = chart;
        ctx.save();
        ctx.font = !this.props.matches768
          ? "bold 18px sans-serif"
          : "bold 11px sans-serif";
        ctx.fillStyle = "white";

        for (let x = 0; x < chart.config.data.datasets.length; x++) {
          for (let i = 0; i < chart.config.data.datasets[x].data.length; i++) {
            let textWidth = ctx.measureText(
              chart.config.data.datasets[x].data[i].status,
            ).width;

            if (i === 0) {
              xOffset = !this.props.matches768 ? 15 : 11;
              yOffset = !this.props.matches768 ? 70 : 40;
            } else {
              xOffset = !this.props.matches768 ? 12 : 7;
              yOffset = !this.props.matches768 ? 55 : 25;
            }

            ctx.fillText(
              `${Math.round((chart.config.data.datasets[x].data[i].y / 1000000 + Number.EPSILON) * 100) / 100}m`,
              chart.getDatasetMeta(x).data[i].x - (textWidth / 2 - xOffset),
              chart.getDatasetMeta(x).data[i].y - yOffset,
            );
          }
        }

        ctx.restore();
      },
    };
  }

  fetchAndSetCurrencyData = () => {
    const url = `https://api.dexscreener.com/latest/dex/tokens/${contracts}`;
    axios
      .get(url)
      .then((response) => {
        let newData = [...this.data];
        contracts.forEach((contract, index) => {
          const firstOccurence = response.data.pairs.find(
            (item) => item.baseToken.address === contract,
          );
          newData[index].market_cap = firstOccurence.fdv;
        });

        let stateCopy = { ...this.state };
        stateCopy.data = newData;
        this.setState(stateCopy);
      })
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    this.fetchAndSetCurrencyData();
    this.interval = setInterval(
      () => this.fetchAndSetCurrencyData(),
      60 * 1000,
    );
  }

  render() {
    return (
      <div
        style={{
          width: "90vw",
          height: "70vh",
          margin: !this.props.matches768 ? "60px" : "25px",
        }}
      >
        {/* Set width and height to match screen */}
        <Scatter
          data={{
            datasets: [
              {
                label: "",
                data: this.state.data.map(({ name, market_cap }) => ({
                  x: name,
                  y: market_cap,
                })),
                pointStyle: this.state.data.map(({ icon }) => icon),
              },
            ],
          }}
          options={{
            scales: {
              x: {
                type: "category", // Set the X scale type to category
                labels: this.state.data.map(({ name }) => name), // Set the labels for the X-axis
                position: "bottom", // Set the X scale position
                offset: true,
                ticks: {
                  color: "#FFFFFF",
                  padding: 10,
                  font: {
                    size: 15,
                  },
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Market cap",
                  color: "#FFFFFF",
                  font: {
                    size: 20,
                  },
                },
                type: "linear", // Set the Y scale type to linear
                position: "left", // Set the Y scale position
                offset: true,
                grace: true,
                ticks: {
                  color: "#FFFFFF",
                  padding: 10,
                  font: {
                    size: 15,
                  },
                  // Include a dollar sign in the ticks
                  callback: function (value, index, ticks) {
                    return value / 1000000 + "m";
                  },
                },
                // max: 1000000000,
              },
            },
            maintainAspectRatio: false, // Set to false to adjust chart size based on container size
            responsive: true, // Set to true for chart responsiveness
          }}
          plugins={[this.scatterDataLabels]}
          redraw
        />
      </div>
    );
  }
}

export default Chart;
