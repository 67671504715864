import { tableContracts } from "../utils/constants";
import axios from "axios";
import beamcat from "../images/beamcat.webp";
import { useEffect, useState } from "react";

const fetchAndSetContractData = (setData) => {
  const url = `https://api.dexscreener.com/latest/dex/tokens/${tableContracts}`;
  axios
    .get(url)
    .then((response) => {
      let newData = [];
      tableContracts.forEach((contract, index) => {
        const firstOccurence = response.data.pairs.find(
          (item) => item.baseToken.address === contract,
        );
        newData.push(firstOccurence);
      });
      setData(newData);
    })
    .catch((err) => console.log(err));
};

export default function Table(props) {
  const [contractData, setContractData] = useState([]);

  useEffect(() => {
    fetchAndSetContractData(setContractData);
    setInterval(() => fetchAndSetContractData(), 60 * 1000);
  }, []);

  return (
    <div className="w-full py-20 px-6 sm:px-14">
      <div
        className="border-solid border rounded-md p-2 w-min mx-6 mb-6 cursor-pointer transition ease-in hover:scale-105 duration-100"
        onClick={() => props.setIsTableView(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="white"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M9.53 2.47a.75.75 0 0 1 0 1.06L4.81 8.25H15a6.75 6.75 0 0 1 0 13.5h-3a.75.75 0 0 1 0-1.5h3a5.25 5.25 0 1 0 0-10.5H4.81l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0Z"
            clipRule="evenodd"
          />
        </svg>
      </div>

      <h2 className="px-4 text-base font-semibold leading-7 text-white sm:px-6 lg:px-8">
        Cat MEEEEEMZ
      </h2>
      <table className="mt-6 pb-6 w-full whitespace-nowrap text-left">
        <colgroup>
          <col className="w-full sm:w-3/12" />
          <col className="lg:w-4/12" />
          <col className="lg:w-2/12" />
          <col className="lg:w-1/12" />
          <col className="lg:w-1/12" />
          <col className="lg:w-1/12" />
        </colgroup>
        <thead className="border-b border-white/10 text-sm leading-6 text-white">
          <tr>
            <th
              scope="col"
              className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8"
            >
              Coin name
            </th>
            <th
              scope="col"
              className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell"
            >
              Contract address
            </th>
            <th
              scope="col"
              className="hidden py-2 pl-0 pr-4 text-right font-semibold md:table-cell sm:pr-8 sm:text-left lg:pr-20"
            >
              Coin ticker
            </th>
            <th
              scope="col"
              className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20"
            >
              Price
            </th>
            <th
              scope="col"
              className="py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8"
            >
              Market cap
            </th>
            <th
              scope="col"
              className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20"
            >
              Website
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-white/5">
          {contractData.map((item) => (
            <tr key={item.url}>
              <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                <div className="flex items-center gap-x-4">
                  <img
                    src={item.info ? item.info.imageUrl : beamcat}
                    alt=""
                    className="h-8 w-8 rounded-full bg-gray-800"
                  />
                  <div className="truncate text-sm font-medium leading-6 text-white">
                    {item.baseToken.name}
                  </div>
                </div>
              </td>
              <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="truncate text-sm leading-6 text-gray-400">
                  {item.baseToken.address}
                </div>
              </td>
              <td className="hidden py-4 pl-0 pr-4 text-sm sm:table-cell leading-6 sm:pr-8 lg:pr-20">
                <div className="flex gap-x-3">
                  <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                    ${item.baseToken.symbol.toUpperCase()}
                  </div>
                </div>
              </td>
              <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20">
                ${item.priceUsd}
              </td>
              <td className="py-4 pl-0 pr-4 text-sm leading-6 text-gray-400 font-bold sm:table-cell sm:pr-6 lg:pr-8">
                {Math.round((item.fdv / 1000000 + Number.EPSILON) * 100) / 100}m
              </td>
              <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20">
                <a
                  href={
                    item.info
                      ? item.info.websites[0].url
                      : "https://beamcat.xyz/"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 transition ease-in hover:scale-110 duration-100"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                    />
                  </svg>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
